function isObject(value : any) {
  return typeof value === 'object' && !Array.isArray(value)
}
// 比较两个对象，并提取出不同的部分
export function getTwoObjectDiff(originalObj : Record<string, any>, diffObj : Record<string, any>) {
  if (!isObject(originalObj) || !isObject(diffObj)) {
    return diffObj
  }
  const diff : Record<string, any> = {}
  for (const key in diffObj) {
    const originalValue = originalObj[key]
    const diffValue = diffObj[key]
    if (JSON.stringify(originalValue) !== JSON.stringify(diffValue)) {
      if (isObject(originalValue) && isObject(diffValue)) {
        const nestedDiff = getTwoObjectDiff(originalValue, diffValue)
        if (Object.keys(nestedDiff).length > 0) {
          diff[key] = nestedDiff
        }
      }
      else {
        diff[key] = diffValue
      }
    }
  }
  return diff
}

//实现字符串中间显示省略号 ...，且左右各显示 10 个字符
export function truncateText(text:string, maxLength:number) {
  if (text.length <= maxLength * 2) {
    return text; // 如果文本长度小于或等于左右各 10 位，不截取
  }
  const start = text.slice(0, maxLength); // 左边 10 个字符
  const end = text.slice(-maxLength); // 右边 10 个字符
  return `${start}...${end}`; // 返回处理后的字符串
}
