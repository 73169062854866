<script setup lang="ts">
  import tron from "@/assets/images/network/tron.png"
  import eth from "@/assets/images/network/eth.png"
  import eventBus from '@/utils/eventBus'
  definePage({
    meta: {
      title: '钱包设置',
    },
  })

  const router = useRouter();

  const list = [
    {
      name: '地址名1',
      address: 'TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ',
      isSelect: true,
      chain: tron
    }, {
      name: '地址名2',
      address: 'TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ',
      isSelect: false,
      chain: eth
    }
  ]

  const VerifyPassword = ref(false);

  const password = ref('');

  const closePwd = () => {
    VerifyPassword.value = false;
  }

  const verifyPwd = () => {
    // console.log(verify, '验证通过')
    router.push('/wallet/backups')
    VerifyPassword.value = false;
  }

  const addAddress = ref(false);

  const closeAddress = () => {
    addAddress.value = false;
  }

  const isShow = ref(false);
  const name = ref('');
</script>

<template>
  <PageLayout navbar navbar-start-side="back">
    <template #navbar-end>
      <span class="c-red font-bold">
        删除
      </span>

    </template>


    <div class="flex flex-1 flex-col gap-8 p-4">
      <div class="flex flex-1 flex-col gap-4">


        <div class="mb-2 mt-4 flex items-center text-4 ">
          <div class="flex flex-1 flex-col items-center gap-2">

            <div class="bg-[var(--g-container-bg)] p-4 border-rd-50">
              <van-icon name="pending-payment" :size="30" />
            </div>

            <div class="mt-2">
              <van-space>

                <span class="font-bold">钱包名</span>

                <van-icon name="edit" @click="isShow = true" />

              </van-space>
            </div>

          </div>



        </div>
        <div class="flex flex-col rounded-lg bg-[var(--g-container-bg)]">
          <div class="flex items-center gap-2 border-b border-b-([var(--g-bg)] solid) p-4 last:border-b-none">

            钱包编号

            <div class="ms-auto flex items-center gap-2 color-[var(--second-txex)]">
              2342522434534
            </div>
          </div>

          <div class="flex items-center gap-2 border-b border-b-([var(--g-bg)] solid) p-4 last:border-b-none">

            创建时间

            <div class="ms-auto flex items-center gap-2 color-[var(--second-txex)]">
              2024-05-12
            </div>
          </div>

          <div class="flex items-center gap-2 border-b border-b-([var(--g-bg)] solid) p-4 last:border-b-none">

            来源

            <div class="ms-auto flex items-center gap-2 color-[var(--second-txex)]">
              通过助记词创建
            </div>
          </div>

        </div>


        <van-cell-group inset>
          <van-cell :center="true" title="备份钱包" is-link @click="VerifyPassword = true">
            <template #value>
              <span class="color-[rgb(var(--ui-primary))]">
                已备份
              </span>
            </template>
          </van-cell>

          <van-cell :center="true" title="忘记密码" is-link to='/wallet/password/explain' />


          <van-cell :center="true" title="密码提示" is-link to='/wallet/password/hint' />



        </van-cell-group>


        <van-row justify="space-between" class="mt-4">
          <van-col span="12">
            <span class="color-[var(--second-txex)]">
              地址
            </span>
          </van-col>
          <van-col span="12" class="text-right">
            <span class="color-[rgb(var(--ui-primary))]" @click="addAddress =true">
              <van-icon name="add" /> 添加地址
            </span>
          </van-col>

        </van-row>

        <van-cell-group inset>
          <van-cell :center="true" :title="item.name" :label="$truncateText(item.address,10)" value=""
            v-for="(item,index) in list" :key="index" is-link @click="router.push('/wallet/address/details')">

            <template #icon>
              <SvgIcon :name="item.chain" :size="30" class="mr-4" />
            </template>
          </van-cell>

        </van-cell-group>

      </div>

    </div>






    <AddAddress :isOpen="addAddress" @close="closeAddress"></AddAddress>


    <van-dialog v-model:show="isShow" title="修改钱包名" show-cancel-button>

      <div class="color-[var(--second-txex)] p-4 text-center text-3">
        输入的名称不能超过6个字符
      </div>

      <van-cell-group inset class="mb-4">

        <van-field v-model="name" type="text" placeholder="钱包名" />

      </van-cell-group>
    </van-dialog>


    <van-dialog v-model:show="VerifyPassword" title="请输入密码" show-cancel-button @cancel="closePwd" @confirm="verifyPwd">

      <van-cell-group inset class="mt-4 mb-4">

        <van-field v-model="password" type="password" placeholder="输入密码" />

      </van-cell-group>
    </van-dialog>

  </PageLayout>
</template>

<style scoped>
  /* 样式 */
  .van-cell-group--inset {
    margin: 0;
  }
</style>
