<script setup lang="ts">
  definePage({
    meta: {
      title: '重置密码',
    },
  })


  const password = ref('');
  const confirm = ref('');
  const message = ref('');
</script>

<template>
  <PageLayout navbar navbar-start-side="back">
    <div class="p-4">

      <div class="font-bold">
        创建新密码
      </div>

      <div class="color-[var(--second-txex)] mt-4 mb-2">
        密码将用于在当前设备的交易授权和钱包解锁 。
      </div>

      <div class="color-[var(--second-txex)] mt-4">
        平台 不会存储你的密码，请妥善保管。
      </div>

    </div>

    <van-cell-group inset title="创建密码">
      <van-field v-model="password" type="password" placeholder="输入密码" />
      <van-field v-model="confirm" type="password" placeholder="重复输入密码" />
    </van-cell-group>

    <van-cell-group inset title="密码提示(可选)">
      <van-field v-model="message" type="text" placeholder="输入提示文字" />
    </van-cell-group>

    <div class="fixed-button p-4 ">
      <van-button block disabled round class="bg-[rgb(var(--ui-primary))] color-[rgb(var(--ui-text))]">提交</van-button>

    </div>
  </PageLayout>
</template>

<style scoped>
  /* 样式 */
</style>
