import { defaultsDeep } from 'lodash-es'
import settingsDefault from '@/settings.default'
import { $t } from '@/locales'

const globalSettings : Settings.all = {
  // 请在此处编写或粘贴配置代码
  tabbar: {
    list: [
      {
        path: '/',
        icon: 'i-ic:sharp-home',
        activeIcon: 'i-ic:twotone-home',
        text: $t('tabbar.index'),
      },
      {
        path: '/user',
        icon: 'i-ic:baseline-person',
        activeIcon: 'i-ic:twotone-person',
        text: $t('tabbar.user'),
      },
    ],
  },
  "app": {
    "colorScheme": localStorage.getItem('colorScheme') as "light" || 'light',
    "lightTheme": localStorage.getItem('lightTheme') || 'light',
    "darkTheme": localStorage.getItem('darkTheme') || 'dark',
    "enableDynamicTitle": false,
    defaultLang: localStorage.getItem('defaultLang') as '',
    defaultCurrency: localStorage.getItem('defaultCurrency') as 'USD',
  },
  "mainPage": {
    "transitionMode": "fade"
  }
}

export default defaultsDeep(globalSettings, settingsDefault) as RecursiveRequired<Settings.all>
