<script setup lang="ts">
  import useSettingsStore from '@/store/modules/settings'

  const settingsStore = useSettingsStore()


  definePage({
    meta: {
      title: '本地货币',
    },
  })

  const list = ['USD', 'CNY', 'TWD'];

  const currencyItems = computed(() => {
    return list.map((item : any) => ({
      currency: item,
      disabled: settingsStore.currency === item,
      handle: () => {
        if (settingsStore.currency !== item) {
          settingsStore.setDefaultCurrency(item)

        }
      },
    }))
  })
</script>

<template>
  <PageLayout navbar navbar-start-side="back">
    <van-cell-group>
      <van-cell :title="item.currency" v-for="(item, index) in currencyItems" :key="index" @click="item.handle">
        <template #right-icon>
          <van-icon name="success" v-if="item.disabled" class="color-[rgb(var(--ui-primary))]" />
        </template>
      </van-cell>
    </van-cell-group>
  </PageLayout>
</template>

<style scoped>
  /* 样式 */
</style>
