<script setup lang="ts">
  import VueQrcode from '@chenfengyuan/vue-qrcode';


  definePage({
    meta: {
      title: 'pages.wallet.receive',
    },
  })

  const val = ref("TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ");
</script>

<template>
  <PageLayout navbar navbar-start-side="back">
    <PageMain class="mt-4 min-h-80 text-center">

      <div class="font-bold m-2">
        仅支持 Tron 资产
      </div>

      <vue-qrcode :value="val" :options="{ width: 320 }"></vue-qrcode>


      <div class="border border-solid border-rd border-color-[var(--second-txex)] p-2 mt-4">
        <div class="color-[var(--second-txex)]">
          钱包地址
        </div>
        <div class="text-center mt-2 font-bold">
          {{val}}
        </div>
      </div>


      <HButton block class="mt-4">
        复制
      </HButton>

    </PageMain>
  </PageLayout>
</template>

<style scoped>

</style>
