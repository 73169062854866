import useSettingsStore from './settings'
import type { settingsType } from '@/utils/watermark'
import watermark from '@/utils/watermark'

const useWatermarkStore = defineStore(
  // 唯一ID
  'watermark',
  () => {
    const settingsStore = useSettingsStore()

    const { init, remove } = watermark()

    const customSettings = ref<settingsType>({})
    const settings = computed({
      get() {
        return Object.assign(
          // 水印更多设置请查看 watermark.ts
          {
            text: `Model`,
            width: 160,
            x: 0,
            y: 0,
            x_space: 25,
            y_space: 25,
            alpha: 0.1,
            color: settingsStore.currentColorScheme === 'light' ? 'black' : 'white',
            parent_node: document.getElementById('app'),
          },
          customSettings.value,
        )
      },
      set(val) {
        update(val)
      },
    })
    watch(settings, () => {
      if (settingsStore.settings.app.enableWatermark) {
        loadWatermark()
      }
    }, {
      deep: true,
    })

    watch(() => settingsStore.settings.app.enableWatermark, (val) => {
      if (val) {
        loadWatermark()
      }
      else {
        removeWatermark()
      }
    }, {
      immediate: true,
    })

    watch(() => settingsStore.currentColorScheme, () => {
      if (settingsStore.settings.app.enableWatermark) {
        loadWatermark()
      }
    })

    function loadWatermark() {
      init(settings.value)
    }

    function removeWatermark() {
      try {
        remove()
      }
      // eslint-disable-next-line unused-imports/no-unused-vars
      catch (err) {}
    }

    // 更新水印设置
    function update(config: settingsType = {}) {
      customSettings.value = config
    }

    return {
      update,
    }
  },
)

export default useWatermarkStore
