<script setup lang="ts">
defineOptions({
  name: '{{ properCase componentName }}',
})

definePage({
  meta: {
    title: '页面暂定',
  },
})
</script>

<template>
  <PageLayout navbar navbar-start-side="back">
    开发中
  </PageLayout>
</template>

<style scoped>
/* 样式 */
</style>
