<script setup lang="ts">
  definePage({
    meta: {
      title: '钱包管理',
    },
  })

  const router = useRouter();

  const list = [
    {
      name: '钱包名称1',
      num: '2'
    },
    {
      name: '钱包名称2',
      num: '1'
    }, {
      name: '钱包名称3',
      num: '1'
    },
    {
      name: '钱包名称4',
      num: '2'
    },
    {
      name: '钱包名称5',
      num: '1'
    }
  ]


  const addAddress = ref(false);

  const closeAddress = () => {
    addAddress.value = false;
  }


  const addWallet = ref(false);

  const isImport = ref(false);
</script>

<template>
  <PageLayout navbar navbar-start-side="back">

    <div class="mt-4">

      <!-- <van-cell-group inset class="mb-4" v-for="(item,index) in list" :key="index">

        <van-cell :title="item.name" label="1个账户" value="" is-link
        @click="router.push('/wallet/manage/setting')">

          <template #icon>
            <van-icon name="pending-payment" :size="30" class="mr-4" />
          </template>

          <template #label>
            <span>
              {{item.num}} 个地址
            </span>
          </template>
        </van-cell>
      </van-cell-group> -->

      <div class="bg-[var(--g-container-bg)] m-4 p-4 border-rd-lg" v-for="(item,index) in list" :key="index">

        <van-row justify="space-between" @click="router.push('/wallet/manage/setting')">
          <van-col span="4">
            <van-icon name="pending-payment" :size="30" class="mr-4" />
          </van-col>

          <van-col span="12">
            <div class="font-bold">
              {{item.name}}
            </div>

            <div class="mt-3 color-[var(--second-txex)]">
              {{item.num}} 个地址
            </div>
          </van-col>

          <van-col span="8" class="text-right">
            <div class="">
              <van-icon name="arrow" class="color-[var(--second-txex)]" />
            </div>

            <div class="mt-3 color-[rgb(var(--ui-primary))]" @click.stop="addAddress= true">
              添加地址
            </div>
          </van-col>
        </van-row>

      </div>


    </div>

    <div class="fixed-button p-2 text-center">
      <van-button round icon="plus" class="bg-[rgb(var(--ui-primary))] color-[rgb(var(--ui-text))]"
        @click="addWallet=true">添加钱包</van-button>
    </div>


    <van-popup v-model:show="addWallet" position="bottom" :style="{ height: '60%' }" class="bg-[var(--g-bg)]"
      :safe-area-inset-bottom="true" round closeable close-icon="close" @close="addWallet = false">
      <div class="p-4">

        <div class="text-center font-bold">
          添加钱包
        </div>


      </div>

      <van-cell-group inset title="需要新钱包">
        <van-cell title="创建钱包" label="生成新的钱包" is-link :center="true" to="/wallet/create">
          <template #icon>
            <van-icon name="paid" :size="30" class="mr-4" />
          </template>
        </van-cell>
      </van-cell-group>

      <van-cell-group inset title="已有钱包">
        <van-cell title="导入钱包" label="使用助记词 / 私钥导入" is-link :center="true" @click="isImport=true">
          <template #icon>
            <van-icon name="records-o" :size="30" class="mr-4" />
          </template>
        </van-cell>
      </van-cell-group>


      <van-popup v-model:show="isImport" position="bottom" :style="{ height: '60%' }" class="bg-[var(--g-bg)]"
        :safe-area-inset-bottom="true" round closeable close-icon="arrow-left" @close="isImport = false"
        close-icon-position="top-left" :close-on-click-overlay="false">

        <div class="p-4 mb-4">

          <div class="text-center font-bold">
            选择导入方式
          </div>


        </div>

        <van-cell-group inset>
          <van-cell title="助记词" label="助记词由单词组成,以空格隔开" is-link :center="true" to="/wallet/create/mnemonics">
            <template #icon>
              <van-icon name="description-o" :size="30" class="mr-4" />
            </template>
          </van-cell>



          <van-cell title="私钥" label="明文私钥字符" is-link :center="true" to="/wallet/create/privateKey">
            <template #icon>
              <van-icon name="flag-o" :size="30" class="mr-4" />
            </template>
          </van-cell>
        </van-cell-group>

      </van-popup>

    </van-popup>

    <AddAddress :isOpen="addAddress" @close="closeAddress"></AddAddress>
  </PageLayout>
</template>

<style scoped>
  /* 样式 */
</style>
