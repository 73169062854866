<script setup lang="ts">
  import useUserStore from '@/store/modules/user'

  definePage({
    meta: {
      title: 'tabbar.user',
      auth: false,
    },
  })

  const userStore = useUserStore()

  const avatarError = ref(false)
  watch(() => userStore.avatar, () => {
    if (avatarError.value) {
      avatarError.value = false
    }
  })
</script>

<template>
  <PageLayout navbar tabbar>
    <van-cell-group>
      <van-cell center title="钱包管理" is-link to="/wallet/manage" label="6个地址">
        <template #icon>
          <van-icon name="paid" class="mr-4" :size="20" />
        </template>
      </van-cell>
    </van-cell-group>


    <van-cell-group class="mt-4">
      <!-- <van-cell center title="地址本" is-link>
        <template #icon>
          <van-icon name="user-circle-o" class="mr-4" :size="20" />
        </template>
      </van-cell> -->
      <van-cell center title="使用设置" is-link to="/user/system">
        <template #icon>
          <van-icon name="setting-o" class="mr-4" :size="20" />
        </template>
      </van-cell>
    </van-cell-group>


    <van-cell-group class="mt-4">
      <van-cell center title="帮助与反馈" is-link to="/user/tentative">
        <template #icon>
          <van-icon name="phone-circle-o" class="mr-4" :size="20" />
        </template>
      </van-cell>
      <van-cell center title="用户协议" is-link to="/user/tentative">
        <template #icon>
          <van-icon name="records-o" class="mr-4" :size="20" />
        </template>
      </van-cell>

      <van-cell center title="关于我们" is-link to="/user/tentative">
        <template #icon>
          <van-icon name="home-o" class="mr-4" :size="20" />
        </template>
      </van-cell>
    </van-cell-group>

  </PageLayout>
</template>
