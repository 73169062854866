<script setup lang="ts">
  definePage({
    meta: {
      title: '',
    },
  })

  const router = useRouter();

  const route = useRoute();


  const type = route.query.type || 1;

  const from = ref({
    name: '',
    pwd: '',
    cfpwd: '',
    hint: ''
  })
</script>

<template>
  <PageLayout navbar navbar-start-side="back">

    <div class="p-4">

      <div v-if="type==1" class="">

        <div class="font-bold">
          创建钱包
        </div>

        <div class="mt-4 color-[var(--second-txex)]">
          为你的多地址钱包命名并设置密码保护。你也可以稍后添加更多钱包。
        </div>

      </div>

      <div v-else class="">

        <div class="font-bold">
          设置你的钱包
        </div>

        <div class="mt-4 color-[var(--second-txex)]">
          为你的钱包设置一个方便自己识别的名称。创建钱包密码，并确保密码的安全。
        </div>

      </div>

    </div>

    <van-cell-group inset title="钱包名称">
      <van-field v-model="from.name" placeholder="6位字符" :maxlength="6" />
    </van-cell-group>

    <van-cell-group inset title="创建密码">
      <van-field v-model="from.pwd" type="password" placeholder="输入密码" />
      <van-field v-model="from.cfpwd" type="password" placeholder="重复输入密码" />
    </van-cell-group>

    <van-cell-group inset title="密码提示(可选)">
      <van-field v-model="from.hint" placeholder="输入提示文字" />
    </van-cell-group>


    <div class="fixed-button p-2 text-center">
      <van-button round block class="bg-[rgb(var(--ui-primary))] color-[rgb(var(--ui-text))]"
        @click="router.push('/wallet/create/after')">
        {{type==1?'创建':'继续'}}
      </van-button>
    </div>
  </PageLayout>
</template>

<style scoped>

</style>
