<script setup lang="ts">
  definePage({
    meta: {
      title: '交易记录',
    },
  })

  const router = useRouter();

  const route = useRoute();

  const active = ref(0);


  const list = [
    {
      from: 'TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ',
      to: 'TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ1',
      amount: '200',
      transactionTime: '2024-9-17 12:22:33',
      type: '1'
    },
    {
      from: 'TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ2',
      to: 'TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ',
      amount: '100',
      transactionTime: '2024-9-17 12:22:33',
      type: '2'
    },
    {
      from: 'TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ2',
      to: 'TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ',
      amount: '100',
      transactionTime: '2024-9-17 12:22:33',
      type: '2'
    },
    {
      from: 'TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ2',
      to: 'TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ',
      amount: '100',
      transactionTime: '2024-9-17 12:22:33',
      type: '1'
    },
    {
      from: 'TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ2',
      to: 'TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ',
      amount: '100',
      transactionTime: '2024-9-17 12:22:33',
      type: '1'
    },
    {
      from: 'TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ2',
      to: 'TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ',
      amount: '100',
      transactionTime: '2024-9-17 12:22:33',
      type: '2'
    }
  ]

  const query = ref({
    address: route.query?.address
  })
</script>

<template>
  <PageLayout navbar navbar-start-side="back">
    <template #navbar>
      {{route.query?.token}}
    </template>

    <van-tabs v-model:active="active">
      <van-tab title="全部" name="0" />
      <van-tab title="转出" name="1" />
      <van-tab title="转入" name="2" />
      <van-tab title="失败" name="3" />
    </van-tabs>

    <div class="">

      <van-cell-group>

        <van-cell center title="地址" label="时间" value="数量" v-for="(item,index) in list" :key="index" @click="router.push('/wallet/trade/details')">
          <!-- mingcute:arrow-down-circle-fill  mingcute:arrow-up-circle-fill mingcute:arrow-to-down-fill mingcute:arrow-to-up-fill-->

          <template #icon>
            <SvgIcon :name="item.type=='1' ? 'mingcute:arrow-to-down-fill' :'mingcute:arrow-to-up-fill'"
              class="font-size-6 mr-4" :class="`${item.type=='1' ? 'c-green' : 'c-red'}`" />
          </template>

          <template #title>
            <span>
              {{$truncateText(item.type=='1' ? item.to:item.from,10)}}
            </span>
          </template>

          <template #label>
            <span>
              {{item.transactionTime}}
            </span>
          </template>


          <template #value>
            <span v-if="item.type=='1'" class="c-green">
              + {{item.amount}}
            </span>

            <span v-else class="c-red">
              - {{item.amount}}
            </span>

          </template>

        </van-cell>

      </van-cell-group>

    </div>


    <div class="fixed-button p-2 bg-[var(--g-container-bg)]">
      <van-row justify="space-between" gutter="20">
        <van-col span="12">
          <van-button type="success" block round @click="router.push('/wallet/receive')">收款</van-button>
        </van-col>

        <van-col span="12">
          <van-button block round class="bg-[rgb(var(--ui-primary))] color-[rgb(var(--ui-text))]"
            @click="router.push('/wallet/transfer')">转账</van-button>
        </van-col>

      </van-row>
    </div>

  </PageLayout>
</template>

<style scoped>

</style>
