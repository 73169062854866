<script setup lang="ts">
  import { localesName } from '@/locales'
  import useSettingsStore from '@/store/modules/settings'

  const settingsStore = useSettingsStore()


  definePage({
    meta: {
      title: '语言设置',
    },
  })

  const langItems = computed(() => {
    return Object.keys(localesName).map((item : any) => ({
      label: localesName[item],
      disabled: settingsStore.lang === item,
      handle: () => {
        if (settingsStore.lang !== item) {
          settingsStore.setDefaultLang(item)

        }
      },
    }))
  })
</script>

<template>
  <PageLayout navbar navbar-start-side="back">

    <van-cell-group>
      <van-cell :title="item.label" v-for="(item, index) in langItems" :key="index" @click="item.handle">
        <template #right-icon>
          <van-icon name="success" v-if="item.disabled" class="color-[rgb(var(--ui-primary))]"/>
        </template>
      </van-cell>
    </van-cell-group>

  </PageLayout>
</template>

<style scoped>

</style>
