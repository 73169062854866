<script setup lang="ts">
  definePage({
    meta: {
      title: '密码提示',
    },
  })

  const value = ref('');
</script>

<template>
  <PageLayout navbar navbar-start-side="back">

    <template #navbar-end>
      <span class="color-[rgb(var(--ui-primary))]">
        完成
      </span>

    </template>


    <div class="mt-4">
      <van-field v-model="value" placeholder="密码提示信息" maxlength="20" show-word-limit />
    </div>

  </PageLayout>
</template>

<style scoped>

</style>
