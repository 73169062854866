<script setup lang="ts">
  definePage({
    meta: {
      title: '备份助记词',
    },
  })

  const mnemonic = 'hospital program perfect whisper vivid approve pigeon obey clown coil ginger supply';

  const list = ref(mnemonic.split(' '))

  const showBottom = ref(true);

  const router = useRouter();
</script>

<template>
  <PageLayout navbar navbar-start-side="back">


    <div class="font-bold p-4">
      请按顺序抄写助记词，确保备份正确。
    </div>

    <PageMain>
      <van-grid :border="false" :column-num="3" :center="false">
        <van-grid-item v-for="(item,index) in list" :key="index">

          <div class="text-right color-[var(--second-txex)]">
            {{index+1}}
          </div>

          <div class="text-center font-bold">
            {{item}}
          </div>

        </van-grid-item>

      </van-grid>
    </PageMain>

    <div class="p-4 color-[var(--second-txex)] ">
      <div class="mt-2">
        ● 妥善保管助记词至隔离网络的安全地方。
      </div>

      <div class="mt-2 ">
        ● 请勿将助记词在联网环境下分享和存储，比如邮件、相册、社交应用等等。
      </div>
    </div>


    <div class="fixed-button p-4 ">
      <van-button block round class="bg-[rgb(var(--ui-primary))] color-[rgb(var(--ui-text))]"
        @click="router.push('/wallet/backups/verifyMnemonic')">已确认备份</van-button>

    </div>

    <van-popup v-model:show="showBottom" position="bottom" :style="{ height: '40%' }" :round="true"
      :safe-area-inset-bottom="true" class="bg-[var(--g-bg)]" closeable close-icon="close">



      <div class="p-4">

        <div class="text-center mt-12 font-bold text-6">
          请勿截图
        </div>

        <div class="mt-4 color-[var(--second-txex)]">
          请不要通过截屏的方式进行备份，这将会增加助记词被盗和丢失的风险。图库一旦被恶意软件窃取，将会造成资产损失。
        </div>

        <van-button block round class="bg-[rgb(var(--ui-primary))] color-[rgb(var(--ui-text))] mt-16"
          @click="showBottom = false">我知道了</van-button>

      </div>

    </van-popup>
  </PageLayout>
</template>

<style scoped>

</style>
