<script setup lang="ts">
  import tron from "@/assets/images/network/tron.png"

  definePage({
    meta: {
      title: '详情',
    },
  })
</script>

<template>
  <PageLayout navbar navbar-start-side="back">

    <div class="text-center mb-10">

      <div class="mt-20">
        <van-icon name="checked" size="4rem" class="c-green" />

      </div>

      <div class="mt-2 font-bold">
        <span class="c-green">成功</span>
      </div>

      <div class="mt-2">
        <span class="color-[var(--second-txex)]">2024-9-17 12:22:33</span>
      </div>

    </div>

    <van-cell-group inset>
      <van-cell center title="地址" label="时间">


        <template #icon>
          <span class="w-16 color-[var(--second-txex)]">
            明细
          </span>
        </template>

        <template #title>
          <span>
            发送
          </span>
        </template>

        <template #label>
          <span class="font-bold color-[rgb(var(--ui-primary))]">
            200 TRX
          </span>
        </template>

        <template #right-icon>
          <SvgIcon :name="tron" :size="40" />
        </template>

      </van-cell>

      <van-cell center title="" label="">

        <template #icon>
          <span class="w-16"> </span>
        </template>

        <template #label>
          <span>
            Tron
          </span>
        </template>

      </van-cell>

      <van-cell center title="地址">


        <template #icon>
          <span class="w-16 color-[var(--second-txex)]">
            矿工费
          </span>
        </template>

        <template #title>
          <span>
            3.2233 TRX
          </span>
        </template>
      </van-cell>
    </van-cell-group>


    <van-cell-group inset class="mt-4">
      <van-cell center title="地址">


        <template #icon>
          <span class="w-16 color-[var(--second-txex)]">
            发起
          </span>
        </template>

        <template #title>
          <span>
            TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ
          </span>
        </template>

      </van-cell>

      <van-cell center title="地址">


        <template #icon>
          <span class="w-16 color-[var(--second-txex)]">
            接收
          </span>
        </template>

        <template #title>
          <span>
            TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ
          </span>
        </template>

      </van-cell>
    </van-cell-group>

    <van-cell-group inset class="mt-4">
      <van-cell center title="地址">


        <template #icon>
          <span class="w-16 color-[var(--second-txex)]">
            交易号
          </span>
        </template>

        <template #title>
          <span>
            66d233c562c187e44ba472f65c3c4695bdca8b444c99f5a94f1039b4a1cd4c8f
          </span>
        </template>

      </van-cell>


    </van-cell-group>

  </PageLayout>
</template>

<style scoped>
  ::v-deep {

    .van-cell__title {
      word-break: break-all;
      word-wrap: break-world;
      white-space: wrap;

    }

    .van-cell__value {
      display: none;
    }

  }
</style>
