<script setup lang="ts">
  definePage({
    meta: {
      title: '确认助记词',
    },
  })

  const { proxy } = getCurrentInstance()!;

  const router = useRouter();

  const mnemonic = 'hospital program perfect whisper vivid approve pigeon obey clown coil ginger supply';

  const list = ref(mnemonic.split(' '));


  const enter = ref([] as string[])


  // 通过一个简单的 shuffle 函数打乱数组
  const shuffleArray = (arr : string[]) => {
    return arr.sort(() => Math.random() - 0.5);
  };

  // 转换为包含对象的数组
  const convertToObjectArray = (array : string[]) => {
    return array.map(item => ({
      val: item,
      isActive: false,
    }));
  };


  // const shuffledArray = shuffleArray(list.value);

  // 打乱并转换
  const shuffledAndConvertedArray = ref(
    convertToObjectArray(shuffleArray([...list.value]))
  );


  const arraysAreEqual = (arr1 : string[], arr2 : string[]) => {
    if (arr1.length !== arr2.length) return false;

    // 比较内容和顺序
    return arr1.every((value, index) => value === arr2[index]);

  };




  const addList = (val : string, index : number) => {
    enter.value.push(val);

    shuffledAndConvertedArray.value[index].isActive = true;
  };


  const delList = (word : string) => {
    enter.value.splice(enter.value.indexOf(word), 1);
    shuffledAndConvertedArray.value = shuffledAndConvertedArray.value.map(item => {
      if (item.val == word) {
        return { ...item, isActive: false }; // 改变 isActive 为 false
      }
      return item; // 返回未改变的对象
    });
  };

  const confirm = () => {
    const result = arraysAreEqual(list.value, enter.value);
    // console.log(result)

    if (!result) {
      proxy?.$message.error('助记词顺序不正确，请校对', {
        zIndex: 2000,
      })

      return;
    }
    proxy?.$message.success('助记词正确')

    setTimeout(() => {
      router.go(-3);
    }, 1000);

  }
</script>

<template>
  <PageLayout navbar navbar-start-side="back">
    <div class="font-bold p-4">
      请按顺序点击助记词，以确认您正确备份。
    </div>

    <PageMain class="min-h-72">
      <van-grid :border="false" :column-num="3" :center="false">
        <van-grid-item v-for="(item,index) in enter" :key="index" @click="delList(item)">

          <div class="text-right color-[var(--second-txex)]">
            {{index+1}}
          </div>

          <div class="text-center font-bold">
            {{item}}
          </div>

        </van-grid-item>

      </van-grid>
    </PageMain>


    <div class="p-4">



      <van-row gutter="20">
        <van-col span="8" v-for="(item,index) in shuffledAndConvertedArray" :key="index" class="mb-2 font-bold">
          <van-button type="default" :disabled="item.isActive"
            @click="addList(item.val,index)">{{item.val}}</van-button>
        </van-col>
      </van-row>

    </div>


    <div class="fixed-button p-4 ">
      <van-button block round class="bg-[rgb(var(--ui-primary))] color-[rgb(var(--ui-text))]" @click="confirm"
        :disabled="list.length!=enter.length">确认</van-button>

    </div>
  </PageLayout>
</template>

<style scoped>
  /* 样式 */
</style>
