<script setup lang="ts">
  import tp from "@/assets/images/wuyn.png"

  definePage({
    meta: {
      title: '',
    },
  })
  const router = useRouter();

</script>

<template>
  <PageLayout navbar navbar-start-side="back" class="bg-[var(--g-container-bg)]">


    <div class="text-center pt-12 mb-6">
      <SvgIcon :name="tp" :size="150" />
    </div>

    <div class="p-4">

      <div class="font-bold text-6">
        备份助记词，保障钱包安全
      </div>

      <div class="color-[var(--second-txex)] mt-4 line-height-6">
        当更换手机或重装应用时，你将需要助记词（12个英文单词）恢复钱包。为保障钱包安全，请务必尽快完成助记词备份。
      </div>

      <div class="mt-4">
        重要提醒：
      </div>
      <div class="mt-2 color-[var(--second-txex)]">
        获得助记词等于拥有钱包资产所有权。
      </div>

      <div class="mt-4">
        如何安全的备份助记词？
      </div>
      <div class="mt-2 color-[var(--second-txex)]">
        ● 使用纸笔，按正确顺序抄写助记词。
      </div>
      <div class="mt-2 color-[var(--second-txex)]">
        ● 将助记词保管至安全的地方。
      </div>
    </div>

    <div class="fixed-button p-4 ">
      <van-button block round class="bg-[rgb(var(--ui-primary))] color-[rgb(var(--ui-text))]"
        @click="router.push('/wallet/backups/mnemonics')">立即备份</van-button>

    </div>

  </PageLayout>
</template>

<style scoped>
  /* 样式 */
</style>
