<script setup lang="ts">
import { Locale } from 'vant'
import { locales } from './index'
import useSettingsStore from '@/store/modules/settings'

const settingsStore = useSettingsStore()

watch(() => settingsStore.lang, () => {
  Locale.use(settingsStore.lang, locales[settingsStore.lang])
})
</script>

<template>
  <VanConfigProvider :theme="settingsStore.currentColorScheme" class="min-h-vh supports-[(min-height:100dvh)]:min-h-dvh">
    <slot />
  </VanConfigProvider>
</template>
