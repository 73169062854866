<script setup lang="ts">
  definePage({
    meta: {
      title: '使用设置',
    },
  })
</script>

<template>
  <PageLayout navbar navbar-start-side="back">


    <van-cell-group>
      <van-cell title="语言设置" is-link to="/user/system/language"/>

      <van-cell title="主题设置" is-link to="/user/system/theme"/>

      <van-cell title="本地货币" is-link to="/user/system/currency"/>
    </van-cell-group>

  </PageLayout>
</template>

<style scoped>
  /* 样式 */
</style>
