import { hex2rgba } from '@unocss/preset-mini/utils'

export default {
  light: {
    // 颜色主题
    'color-scheme': 'light',
    // 内置 UI
    '--ui-primary': hex2rgba('#0f0f0f')!.join(' '),
    '--ui-text': hex2rgba('#fcfcfc')!.join(' '),
    // 主体
    '--g-bg': '#f2f2f2',
    '--g-container-bg': '#fff',
    '--g-border-color': '#DCDFE6',
    // 导航栏
    '--g-navbar-bg': '#fff',
    '--g-navbar-color': '#0f0f0f',
    // 标签栏
    '--g-tabbar-bg': '#fff',
    '--g-tabbar-color': '#6f6f6f',
    '--g-tabbar-active-color': '#0f0f0f',

    //二级颜色
    '--second-txex':'#6d6d6d',
  },
  classic: {
    // 颜色主题
    'color-scheme': 'light',
    // 内置 UI
    '--ui-primary': hex2rgba('#409eff')!.join(' '),
    '--ui-text': hex2rgba('#fcfcfc')!.join(' '),
    // 主体
    '--g-bg': '#f2f2f2',
    '--g-container-bg': '#fff',
    '--g-border-color': '#DCDFE6',
    // 导航栏
    '--g-navbar-bg': '#fff',
    '--g-navbar-color': '#409eff',
    // 标签栏
    '--g-tabbar-bg': '#fff',
    '--g-tabbar-color': '#6f6f6f',
    '--g-tabbar-active-color': '#409eff',
    //二级颜色
    '--second-txex':'#6d6d6d',
  },
  naive: {
    // 颜色主题
    'color-scheme': 'light',
    // 内置 UI
    '--ui-primary': hex2rgba('#18a058')!.join(' '),
    '--ui-text': hex2rgba('#fcfcfc')!.join(' '),
    // 主体
    '--g-bg': '#f2f2f2',
    '--g-container-bg': '#fff',
    '--g-border-color': '#DCDFE6',
    // 导航栏
    '--g-navbar-bg': '#fff',
    '--g-navbar-color': '#18a058',
    // 标签栏
    '--g-tabbar-bg': '#fff',
    '--g-tabbar-color': '#6f6f6f',
    '--g-tabbar-active-color': '#18a058',
    //二级颜色
    '--second-txex':'#6d6d6d',
  },
  barbie: {
    // 颜色主题
    'color-scheme': 'light',
    // 内置 UI
    '--ui-primary': hex2rgba('#ff43bc')!.join(' '),
    '--ui-text': hex2rgba('#fcfcfc')!.join(' '),
    // 主体
    '--g-bg': '#f2f2f2',
    '--g-container-bg': '#fff',
    '--g-border-color': '#DCDFE6',
    // 导航栏
    '--g-navbar-bg': '#fff',
    '--g-navbar-color': '#ff43bc',
    // 标签栏
    '--g-tabbar-bg': '#fff',
    '--g-tabbar-color': '#6f6f6f',
    '--g-tabbar-active-color': '#ff43bc',
    //二级颜色
    '--second-txex':'#6d6d6d',
  },
  winter: {
    // 颜色主题
    'color-scheme': 'light',
    // 内置 UI
    '--ui-primary': hex2rgba('#a6e4f8')!.join(' '),
    '--ui-text': hex2rgba('#3e4e68')!.join(' '),
    // 主体
    '--g-bg': '#f2f2f2',
    '--g-container-bg': '#fff',
    '--g-border-color': '#DCDFE6',
    // 导航栏
    '--g-navbar-bg': '#fff',
    '--g-navbar-color': '#3e4e68',
    // 标签栏
    '--g-tabbar-bg': '#fff',
    '--g-tabbar-color': '#6f6f6f',
    '--g-tabbar-active-color': '#3e4e68',
    //二级颜色
    '--second-txex':'#6d6d6d',
  },
  cyberpunk: {
    // 颜色主题
    'color-scheme': 'light',
    // 内置 UI
    '--ui-primary': hex2rgba('#dbcb00')!.join(' '),
    '--ui-text': hex2rgba('#fcfcfc')!.join(' '),
    // 主体
    '--g-bg': '#f2f2f2',
    '--g-container-bg': '#fff',
    '--g-border-color': '#DCDFE6',
    // 导航栏
    '--g-navbar-bg': '#fff',
    '--g-navbar-color': '#dbcb00',
    // 标签栏
    '--g-tabbar-bg': '#fff',
    '--g-tabbar-color': '#6f6f6f',
    '--g-tabbar-active-color': '#dbcb00',
    //二级颜色
    '--second-txex':'#6d6d6d',
  },
  dark: {
    // 颜色主题
    'color-scheme': 'dark',
    // 内置 UI
    '--ui-primary': hex2rgba('#e5e5e5')!.join(' '),
    '--ui-text': hex2rgba('#242b33')!.join(' '),
    // 主体
    '--g-bg': '#0a0a0a',
    '--g-container-bg': '#141414',
    '--g-border-color': '#15191e',
    // 导航栏
    '--g-navbar-bg': '#141414',
    '--g-navbar-color': '#e5e5e5',
    // 标签栏
    '--g-tabbar-bg': '#141414',
    '--g-tabbar-color': '#6f6f6f',
    '--g-tabbar-active-color': '#e5e5e5',
    //二级颜色
    '--second-txex':'#bdbdbd',
  },
  dracula: {
    // 颜色主题
    'color-scheme': 'dark',
    // 内置 UI
    '--ui-primary': hex2rgba('#a6adbb')!.join(' '),
    '--ui-text': hex2rgba('#242b33')!.join(' '),
    // 主体
    '--g-bg': '#272935',
    '--g-container-bg': '#1d232a',
    '--g-border-color': '#191E24',
    // 导航栏
    '--g-navbar-bg': '#141414',
    '--g-navbar-color': '#a6adbb',
    // 标签栏
    '--g-tabbar-bg': '#141414',
    '--g-tabbar-color': '#414558',
    '--g-tabbar-active-color': '#a6adbb',
    //二级颜色
    '--second-txex':'#bdbdbd',
  },
  night: {
    // 颜色主题
    'color-scheme': 'dark',
    // 内置 UI
    '--ui-primary': hex2rgba('#0ca6e9')!.join(' '),
    '--ui-text': hex2rgba('#242b33')!.join(' '),
    // 主体
    '--g-bg': '#0f1729',
    '--g-container-bg': '#1d283a',
    '--g-border-color': '#191E24',
    // 导航栏
    '--g-navbar-bg': '#141414',
    '--g-navbar-color': '#0ca6e9',
    // 标签栏
    '--g-tabbar-bg': '#141414',
    '--g-tabbar-color': '#414558',
    '--g-tabbar-active-color': '#0ca6e9',
    //二级颜色
    '--second-txex':'#bdbdbd',
  },
  luxury: {
    // 颜色主题
    'color-scheme': 'dark',
    // 内置 UI
    '--ui-primary': hex2rgba('#dca54c')!.join(' '),
    '--ui-text': hex2rgba('#242b33')!.join(' '),
    // 主体
    '--g-bg': '#09090b',
    '--g-container-bg': '#171618',
    '--g-border-color': '#191E24',
    // 导航栏
    '--g-navbar-bg': '#141414',
    '--g-navbar-color': '#dca54c',
    // 标签栏
    '--g-tabbar-bg': '#141414',
    '--g-tabbar-color': '#414558',
    '--g-tabbar-active-color': '#dca54c',
    //二级颜色
    '--second-txex':'#bdbdbd',
  },
  synthwave: {
    // 颜色主题
    'color-scheme': 'dark',
    // 内置 UI
    '--ui-primary': hex2rgba('#58c7f3')!.join(' '),
    '--ui-text': hex2rgba('#242b33')!.join(' '),
    // 主体
    '--g-bg': '#1a103c',
    '--g-container-bg': '#221551',
    '--g-border-color': '#191E24',
    // 导航栏
    '--g-navbar-bg': '#141414',
    '--g-navbar-color': '#58c7f3',
    // 标签栏
    '--g-tabbar-bg': '#141414',
    '--g-tabbar-color': '#414558',
    '--g-tabbar-active-color': '#58c7f3',
    //二级颜色
    '--second-txex':'#bdbdbd',
  },
  stone: {
    // 颜色主题
    'color-scheme': 'dark',
    // 内置 UI
    '--ui-primary': hex2rgba('#68d1bf')!.join(' '),
    '--ui-text': hex2rgba('#242b33')!.join(' '),
    // 主体
    '--g-bg': '#1b1917',
    '--g-container-bg': '#282524',
    '--g-border-color': '#43403c',
    // 导航栏
    '--g-navbar-bg': '#141414',
    '--g-navbar-color': '#68d1bf',
    // 标签栏
    '--g-tabbar-bg': '#141414',
    '--g-tabbar-color': '#414558',
    '--g-tabbar-active-color': '#68d1bf',
    //二级颜色
    '--second-txex':'#bdbdbd',
  },
}
