<script setup lang="ts">
  import tron from "@/assets/images/network/tron.png"

  definePage({
    meta: {
      title: '地址详情',
    },
  })

  const details = ref({
    name: 'Tron地址1',
    address: 'TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJTQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ',
    chain: tron
  })

  const isShow = ref(false);
  const name = ref('');
</script>

<template>
  <PageLayout navbar navbar-start-side="back">

    <template #navbar-end>

      <span class="c-red font-bold">
        删除
      </span>

    </template>

    <div class="main-wrap">


      <van-cell-group title="钱包名" inset>
        <van-cell :center="true" title="地址" value="">

          <template #icon>
            <SvgIcon :name="tron" :size="30" class="mr-4" />
          </template>

          <template #title>
            <span>地址名</span>
          </template>

          <template #right-icon>
            <van-icon name="edit" @click="isShow=true" />
          </template>
        </van-cell>

      </van-cell-group>


      <van-cell-group inset class="mt-4">
        <van-cell :center="true" title="地址" :label="details.address" value="">

          <template #right-icon>
            <SvgIcon name="ant-design:copy-outlined" />
          </template>
        </van-cell>

      </van-cell-group>

      <!-- <van-cell-group inset class="mt-4">
      <van-cell :center="true" title="导出私钥" is-link>
      </van-cell>

    </van-cell-group> -->
    </div>

    <van-dialog v-model:show="isShow" title="修改地址名" show-cancel-button>

      <div class="color-[var(--second-txex)] p-4 text-center text-3">
        输入的名称不能超过6个字符
      </div>

      <van-cell-group inset class="mb-4">

        <van-field v-model="name" type="text" placeholder="地址名" />

      </van-cell-group>


    </van-dialog>
  </PageLayout>
</template>

<style scoped>
  ::v-deep {

    .main-wrap .van-cell__label {
      word-break: break-all;
      word-wrap: break-world;
      white-space: wrap;
    }

    .main-wrap .van-cell__value {
      display: none;
    }

  }
</style>
