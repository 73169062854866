<script setup lang="ts">
  definePage({
    meta: {
      title: '重置密码',
    },
  })

  const router = useRouter();

  const message = ref('');
</script>

<template>
  <PageLayout navbar navbar-start-side="back">

    <div class="p-4">

      <div class="font-bold">
        验证助记词
      </div>

      <div class="color-[var(--second-txex)] mt-4 mb-4">
        提供当前钱包的正确助记词以完成验证。
      </div>


      <van-field v-model="message" rows="3" autosize type="textarea" placeholder="输入助记词单词,并使用空格分隔" />

    </div>

    <div class="fixed-button p-4 ">
      <van-button block round class="bg-[rgb(var(--ui-primary))] color-[rgb(var(--ui-text))]"
        @click="router.push('/wallet/password/reset')">验证</van-button>

    </div>

  </PageLayout>
</template>

<style scoped>
  /* 样式 */
</style>
