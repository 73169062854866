<script setup lang="ts">
  definePage({
    meta: {
      title: '忘记密码',
    },
  })

  const router = useRouter();
</script>

<template>
  <PageLayout navbar navbar-start-side="back" class="bg-[var(--g-container-bg)]">

    <PageMain>

      <div class="bg-[rgb(var(--ui-primary))] p-4 border-rd-50 inline-block">
        <van-icon name="question-o" :size="30" class="color-[var(--g-container-bg)]" />
      </div>

      <div class="mt-4 color-[var(--second-txex)] line-height-8">
        平台 不会存储你的钱包密码。如果你忘记了密码或创建一个新密码，你可以通过提供正确的助记词或私钥来重置密码。
      </div>

    </PageMain>

    <van-cell-group inset>
      <van-cell title="验证" label="提供助记词或私钥">
        <template #icon>
          <van-icon name="shield-o" :size="30" class="color-[rgb(var(--ui-primary))] mr-4" />
        </template>

        <template #title>
          <span class="font-bold">验证</span>
        </template>

      </van-cell>

      <van-cell title="创建新密码" label="请妥善保管,仅供自己使用">
        <template #icon>
          <van-icon name="certificate" :size="30" class="color-[rgb(var(--ui-primary))] mr-4" />
        </template>
        <template #title>
          <span class="font-bold">创建新密码</span>
        </template>
      </van-cell>
    </van-cell-group>


    <div class="fixed-button p-4 ">
      <van-button block round class="bg-[rgb(var(--ui-primary))] color-[rgb(var(--ui-text))]"
        @click="router.push('/wallet/password/verify')">重置密码</van-button>

    </div>

  </PageLayout>
</template>

<style scoped>
  /* 样式 */
</style>
