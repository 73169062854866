// 该文件为系统默认配置，请勿修改！！！

const globalSettingsDefault: RecursiveRequired<Settings.all> = {
  app: {
    lightTheme: 'light',
    darkTheme: 'dark',
    colorScheme: 'light',
    enableMournMode: false,
    enableColorAmblyopiaMode: false,
    defaultLang: '',
    enablePermission: false,
    enableProgress: true,
    enableDynamicTitle: false,
    enableBackTop: true,
    storagePrefix: 'fm_',
    enableWatermark: false,
    enableErrorLog: false,
    defaultCurrency:'USD'
  },
  navbar: {
    enable: false,
    mode: 'static',
  },
  tabbar: {
    enable: false,
    list: [],
  },
  mainPage: {
    enableTransition: true,
    transitionMode: 'fade',
  },
  copyright: {
    enable: false,
    dates: '',
    company: '',
    website: '',
    beian: '',
  },
}

export default globalSettingsDefault
