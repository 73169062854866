import type { App } from 'vue'
import Vant from 'vant'
import 'vant/lib/index.css'
import '@vant/touch-emulator'
import enUS from 'vant/es/locale/lang/en-US'
import zhCN from 'vant/es/locale/lang/zh-CN'

function install(app: App) {
  app.use(Vant)
}

// 此处的对象属性和 src/locales/index.ts 中的 messages 对象属性一一对应
const locales: Record<string, any> = {
  'zh-cn': zhCN,
  'en-us': enUS,
}

export default { install }
export { locales }
