<script setup lang="ts">
  definePage({
    meta: {
      title: '导入助记词',
    },
  })

  const router = useRouter();

  const value = ref('');
</script>

<template>
  <PageLayout navbar navbar-start-side="back">

    <div class="p-4 mb-4">

      <div class="color-[var(--second-txex)]">
        输入助记词来添加或恢复你的钱包。导入的助记词将被加密并安全存储在你的设备上。为了你的资产安全，平台不会存储你的助记词。
      </div>

    </div>

    <van-cell-group inset>
      <van-field v-model="value" rows="3" autosize type="textarea" placeholder="输入助记词单词,并使用空格分隔" />
    </van-cell-group>

    <div class="fixed-button p-2 text-center">
      <van-button round block class="bg-[rgb(var(--ui-primary))] color-[rgb(var(--ui-text))]"
      @click="router.push({
        path:'/wallet/create',
        query:{type:2}
      })">
        马上导入
      </van-button>
    </div>

  </PageLayout>
</template>

<style scoped>
  /* 样式 */
</style>
