import apiUser from '@/api/modules/user'
import router from '@/router'
import storage from '@/utils/storage'

const useUserStore = defineStore(
  // 唯一ID
  'user',
  () => {
    const account = ref(storage.local.get('account') ?? '')
    const token = ref(storage.local.get('token') ?? '')
    const avatar = ref(storage.local.get('avatar') ?? '')
    const isGetPermissions = ref(false)
    const permissions = ref<string[]>([])

    const isLogin = computed(() => {
      if (token.value) {
        return true
      }
      return false
    })

    function login(data: {
      account: string
      password: string
    }) {
      return new Promise((resolve, reject) => {
        apiUser.login(data).then((res) => {
          storage.local.set('account', res.data.account)
          storage.local.set('token', res.data.token)
          storage.local.set('avatar', res.data.avatar)
          account.value = res.data.account
          token.value = res.data.token
          avatar.value = res.data.avatar
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    }
    function logout() {
      // 模拟退出登录，清除 token 信息
      storage.local.remove('account')
      storage.local.remove('token')
      storage.local.remove('avatar')
      account.value = ''
      token.value = ''
      avatar.value = ''
      router.push('/')
    }
    // 获取权限
    async function getPermissions() {
      const res = await apiUser.permission()
      permissions.value = res.data.permissions
      isGetPermissions.value = true
    }

    return {
      account,
      token,
      avatar,
      isLogin,
      isGetPermissions,
      permissions,
      login,
      logout,
      getPermissions,
    }
  },
)

export default useUserStore
