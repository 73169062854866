<script setup lang="ts">
  import tp from '@/assets/images/tjdzts.jpeg';

  definePage({
    meta: {
      title: '添加地址',
    },
  })

  const router = useRouter();

  const addAddress = ref(false);

  const closeAddress = () => {
    addAddress.value = false;
  }

  const addFinish = () => {
    router.push('/wallet/backups')
  }
</script>

<template>
  <PageLayout navbar navbar-start-side="back" class="bg-[var(--g-container-bg)]">

    <div class="p-4">
      <van-image :src="tp">
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>


      <div class="text-center mt-4">
        <div class="font-bold">
          为你的钱包添加地址
        </div>

        <div class="mt-4 color-[var(--second-txex)]">
          你需要添加至少一个地址后开始使用。当你选择公链和网络后，地址将被创建。
        </div>
      </div>

    </div>


    <div class="fixed-button p-2 text-center">
      <van-button round block class="bg-[rgb(var(--ui-primary))] color-[rgb(var(--ui-text))]" @click="addAddress=true">
        立即添加地址
      </van-button>
    </div>


    <AddAddress :isOpen="addAddress" @close="closeAddress" @finish="addFinish"></AddAddress>

  </PageLayout>
</template>

<style scoped>

</style>
