import './utils/system.copyright'

import Message from 'vue-m-message'
import 'vue-m-message/dist/style.css'

import 'overlayscrollbars/overlayscrollbars.css'

import App from './App.vue'
import pinia from './store'
import router from './router'
import ui from './ui-provider'
import { setupI18n } from './locales'

import { showToast } from 'vant';

// 自定义指令
import directive from '@/utils/directive'


// 错误日志上报
import errorLog from '@/utils/error.log'

// 加载 svg 图标
import 'virtual:svg-icons-register'

import 'virtual:uno.css'

// 全局样式
import '@/assets/styles/globals.css'

//全局方法
import { truncateText } from '@/utils/index.ts'

const app = createApp(App)


// 挂载全局方法到 Vue 实例
app.config.globalProperties.$truncateText = truncateText;

// 将 Message 挂载到全局
app.config.globalProperties.$message = Message;

app.use(Message)
app.use(pinia)
app.use(router)
app.use(ui)
app.use(setupI18n())
directive(app)
errorLog(app)

app.mount('#app')
