<script setup lang="ts">
  import tron from "@/assets/images/network/tron.png"

  definePage({
    meta: {
      title: '转账',
    },
  })

  const addres = ref('');
  const number = ref('');

  const onSubmit = (values : string) => {
    console.log('submit', values);
  };

  const showBottom = ref(false);
</script>

<template>
  <PageLayout navbar navbar-start-side="back">

    <div class="">

      <div class="color-[var(--second-txex)] p-4">
        收款地址
      </div>

      <van-cell-group inset>
        <van-field v-model="addres" label="" placeholder="TRX 地址" />
      </van-cell-group>

      <div class="color-[var(--second-txex)] p-4">
        <van-row justify="space-between">
          <van-col span="10">
            数量
          </van-col>
          <van-col span="14" class="text-right">
            0 TRX
          </van-col>
        </van-row>
      </div>


      <van-cell-group inset>
        <van-field v-model="number" type="number" label="" placeholder="0" class="font-bold font-size-6 h-16"
          :center="true" />
        <van-field v-model="addres" label="" placeholder="备注" />
      </van-cell-group>

      <div class="color-[var(--second-txex)] p-4">
        矿工费
      </div>

      <van-cell-group inset>
        <van-cell center title="$0.03" value="" label="0.2TRX" />
      </van-cell-group>

      <div class="p-4 mt-8">
        <HButton block @click="showBottom = true">
          下一步
        </HButton>
      </div>

    </div>



    <van-popup v-model:show="showBottom" position="bottom" :style="{ height: '60%' }" :round="true"
      :safe-area-inset-bottom="true" class="bg-[var(--g-bg)]">

      <div class="p-4">

        <div class="text-center">

          <div class="mt-4">
            <SvgIcon name="material-symbols:add-to-home-screen-outline"
              class="font-size-10 color-[rgb(var(--ui-primary))]" />
          </div>

          <div class="mt-2">
            发送至地址
          </div>

          <div class="font-bold mt-2">
            TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ
          </div>

          <div class="font-bold mt-2">
            <van-space size="0.5rem" align="center" class="color-[var(--second-txex)]">
              <SvgIcon :name="tron" class="font-size-6" />

              <span>
                Tron
              </span>

            </van-space>
          </div>
        </div>

        <div class="mt-4">

          <van-cell title="数量" label="TRX" value="0" :center="true">
            <template #value>
              <span class="font-bold font-size-6 color-[rgb(var(--ui-primary))]">0</span>
            </template>
          </van-cell>



          <van-cell-group title="">
            <van-cell title="发送地址" label="地址名称" value="TQe2RNzZZsdFc5vSBxLG8A9B8fXM9RmUtJ" :center="true" />

            <van-cell title="矿工费" value="0 TRX" />
          </van-cell-group>

        </div>

        <van-row justify="space-between" gutter="20" class="mt-8">
          <van-col span="12">
            <van-button type="default" block round @click="showBottom = false">取消</van-button>
          </van-col>

          <van-col span="12">
            <van-button block round class="bg-[rgb(var(--ui-primary))] color-[rgb(var(--ui-text))]" @click="showBottom = false">发送</van-button>
          </van-col>

        </van-row>

      </div>


    </van-popup>
  </PageLayout>
</template>

<style scoped>

</style>
