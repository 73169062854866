<script setup lang="ts">
import themes from '../../../../themes'

import useSettingsStore from '@/store/modules/settings'

definePage({
  meta: {
    title: '主题设置',
  },
})

const settingsStore = useSettingsStore()

const themeList = computed(() => {
  return Object.keys(themes).map((key) => {
    return {
      label: key,
      value: (themes as any)[key],
    }
  }).filter(item => item.value['color-scheme'] === settingsStore.currentColorScheme)
})

</script>

<template>
  <PageLayout navbar navbar-start-side="back" class="p-4">

    <div>
      <div class="my-4 flex items-center justify-between gap-4 whitespace-nowrap text-sm font-500 after:(h-[1px] w-full bg-stone-2 content-empty dark-bg-stone-6) before:(h-[1px] w-full bg-stone-2 content-empty dark-bg-stone-6)">
        颜色主题风格
      </div>
      <div class="flex items-center justify-center pb-4">
        <HTabList
          v-model="settingsStore.settings.app.colorScheme"
          :options="[
            { icon: 'i-ri:sun-line', label: '明亮', value: 'light' },
            { icon: 'i-ri:moon-line', label: '暗黑', value: 'dark' },
            { icon: 'i-codicon:color-mode', label: '系统', value: '' },
          ]"
          class="w-60"
        />
      </div>
      <div class="flex flex-wrap items-center justify-center gap-4 pb-4">
        <div
          v-for="item in themeList" :key="item.label" class="h-12 w-12 flex cursor-pointer items-center justify-center rounded-2 ring-1 ring-stone-2 transition dark-ring-stone-7" :class="{
            'ring-ui-primary! ring-2': settingsStore.currentColorScheme === 'dark' ? settingsStore.settings.app.darkTheme === item.label : settingsStore.settings.app.lightTheme === item.label,
          }" @click="settingsStore.currentColorScheme === 'dark' ? settingsStore.settings.app.darkTheme = item.label : settingsStore.settings.app.lightTheme = item.label"
        >
          <div
            class="h-4 w-6 rounded-1/2 transition -rotate-45" :class="{
              'rotate-0!': settingsStore.currentColorScheme === 'dark' ? settingsStore.settings.app.darkTheme === item.label : settingsStore.settings.app.lightTheme === item.label,
            }" :style="`background-color: rgb(${item.value['--ui-primary']});`"
          />
        </div>
      </div>
    </div>
    <div>
      <div class="my-4 flex items-center justify-between gap-4 whitespace-nowrap text-sm font-500 after:(h-[1px] w-full bg-stone-2 content-empty dark-bg-stone-6) before:(h-[1px] w-full bg-stone-2 content-empty dark-bg-stone-6)">
        页面切换动画
      </div>
      <div class="flex items-center justify-center pb-4">
        <HToggle v-model="settingsStore.settings.mainPage.enableTransition" />
      </div>
      <div v-if="settingsStore.settings.mainPage.enableTransition" class="flex items-center justify-center gap-4 pb-4">
        <div class="mode mode-fade relative h-10 w-14 flex cursor-pointer items-center justify-center rounded-2 ring-1 ring-stone-2 dark-ring-stone-7" :class="{ 'ring-ui-primary! ring-2': settingsStore.settings.mainPage.transitionMode === 'fade' }" @click="settingsStore.settings.mainPage.transitionMode = 'fade'" />
        <div class="mode mode-slide-left relative h-10 w-14 flex cursor-pointer items-center justify-center rounded-2 ring-1 ring-stone-2 dark-ring-stone-7" :class="{ 'ring-ui-primary! ring-2': settingsStore.settings.mainPage.transitionMode === 'slide-left' }" @click="settingsStore.settings.mainPage.transitionMode = 'slide-left'" />
        <div class="mode mode-slide-right relative h-10 w-14 flex cursor-pointer items-center justify-center rounded-2 ring-1 ring-stone-2 dark-ring-stone-7" :class="{ 'ring-ui-primary! ring-2': settingsStore.settings.mainPage.transitionMode === 'slide-right' }" @click="settingsStore.settings.mainPage.transitionMode = 'slide-right'" />
        <div class="mode mode-slide-top relative h-10 w-14 flex cursor-pointer items-center justify-center rounded-2 ring-1 ring-stone-2 dark-ring-stone-7" :class="{ 'ring-ui-primary! ring-2': settingsStore.settings.mainPage.transitionMode === 'slide-top' }" @click="settingsStore.settings.mainPage.transitionMode = 'slide-top'" />
        <div class="mode mode-slide-bottom relative h-10 w-14 flex cursor-pointer items-center justify-center rounded-2 ring-1 ring-stone-2 dark-ring-stone-7" :class="{ 'ring-ui-primary! ring-2': settingsStore.settings.mainPage.transitionMode === 'slide-bottom' }" @click="settingsStore.settings.mainPage.transitionMode = 'slide-bottom'" />
      </div>
    </div>

  </PageLayout>
</template>

<style scoped>
.mode {
  &::after {
    --uno: content-empty absolute w-3/5 h-3/5 top-1/5 left-1/5 rounded-2 bg-stone-2 dark-bg-stone-9 transition;
  }

  &.mode-fade {
    &::after {
      animation: transition-mode-fade-after 1.5s infinite;
    }

    @keyframes transition-mode-fade-after {
      0% {
        opacity: 1;
      }

      30%,
      35% {
        opacity: 0;
      }

      65% {
        opacity: 1;
      }
    }
  }

  &.mode-slide-left {
    &::after {
      animation: transition-mode-slide-left 1.5s infinite;
    }

    @keyframes transition-mode-slide-left {
      0% {
        opacity: 1;
      }

      30% {
        opacity: 0;
        transform: translate(-30%, 0);
      }

      35% {
        opacity: 0;
        transform: translate(30%, 0);
      }

      65% {
        opacity: 1;
      }
    }
  }

  &.mode-slide-right {
    &::after {
      animation: transition-mode-slide-right 1.5s infinite;
    }

    @keyframes transition-mode-slide-right {
      0% {
        opacity: 1;
      }

      30% {
        opacity: 0;
        transform: translate(30%, 0);
      }

      35% {
        opacity: 0;
        transform: translate(-30%, 0);
      }

      100% {
        opacity: 1;
      }
    }
  }

  &.mode-slide-top {
    &::after {
      animation: transition-mode-slide-top 1.5s infinite;
    }

    @keyframes transition-mode-slide-top {
      0% {
        opacity: 1;
      }

      30% {
        opacity: 0;
        transform: translate(0, -30%);
      }

      35% {
        opacity: 0;
        transform: translate(0, 30%);
      }

      100% {
        opacity: 1;
      }
    }
  }

  &.mode-slide-bottom {
    &::after {
      animation: transition-mode-slide-bottom 1.5s infinite;
    }

    @keyframes transition-mode-slide-bottom {
      0% {
        opacity: 1;
      }

      30% {
        opacity: 0;
        transform: translate(0, 30%);
      }

      35% {
        opacity: 0;
        transform: translate(0, -30%);
      }

      100% {
        opacity: 1;
      }
    }
  }
}
</style>
