import _definePage_default_0 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/[...all].vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/login.vue?definePage&vue&lang.tsx'
import _definePage_default_3 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/reload.vue?definePage&vue&lang.tsx'
import _definePage_default_4 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/user/index.vue?definePage&vue&lang.tsx'
import _definePage_default_5 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/user/system/index.vue?definePage&vue&lang.tsx'
import _definePage_default_6 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/user/system/currency.vue?definePage&vue&lang.tsx'
import _definePage_default_7 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/user/system/language.vue?definePage&vue&lang.tsx'
import _definePage_default_8 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/user/system/theme.vue?definePage&vue&lang.tsx'
import _definePage_default_9 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/user/tentative.vue?definePage&vue&lang.tsx'
import _definePage_default_10 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/address/details.vue?definePage&vue&lang.tsx'
import _definePage_default_11 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/backups/index.vue?definePage&vue&lang.tsx'
import _definePage_default_12 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/backups/mnemonics.vue?definePage&vue&lang.tsx'
import _definePage_default_13 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/backups/verifyMnemonic.vue?definePage&vue&lang.tsx'
import _definePage_default_14 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/create/index.vue?definePage&vue&lang.tsx'
import _definePage_default_15 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/create/after.vue?definePage&vue&lang.tsx'
import _definePage_default_16 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/create/mnemonics.vue?definePage&vue&lang.tsx'
import _definePage_default_17 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/create/privateKey.vue?definePage&vue&lang.tsx'
import _definePage_default_18 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/manage/index.vue?definePage&vue&lang.tsx'
import _definePage_default_19 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/manage/setting.vue?definePage&vue&lang.tsx'
import _definePage_default_20 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/password/explain.vue?definePage&vue&lang.tsx'
import _definePage_default_21 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/password/hint.vue?definePage&vue&lang.tsx'
import _definePage_default_22 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/password/reset.vue?definePage&vue&lang.tsx'
import _definePage_default_23 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/password/verify.vue?definePage&vue&lang.tsx'
import _definePage_default_24 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/receive.vue?definePage&vue&lang.tsx'
import _definePage_default_25 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/trade/details.vue?definePage&vue&lang.tsx'
import _definePage_default_26 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/trade/record.vue?definePage&vue&lang.tsx'
import _definePage_default_27 from 'D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/transfer.vue?definePage&vue&lang.tsx'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/index.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/[...all].vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/login.vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  _mergeRouteRecord(
  {
    path: '/reload',
    name: 'reload',
    component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/reload.vue'),
    /* no children */
  },
  _definePage_default_3
  ),
  {
    path: '/user',
    /* internal name: '/user' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/user/',
        component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/user/index.vue'),
        /* no children */
      },
  _definePage_default_4
  ),
      {
        path: 'system',
        /* internal name: '/user/system' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/user/system/',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/user/system/index.vue'),
            /* no children */
          },
  _definePage_default_5
  ),
  _mergeRouteRecord(
          {
            path: 'currency',
            name: '/user/system/currency',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/user/system/currency.vue'),
            /* no children */
          },
  _definePage_default_6
  ),
  _mergeRouteRecord(
          {
            path: 'language',
            name: '/user/system/language',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/user/system/language.vue'),
            /* no children */
          },
  _definePage_default_7
  ),
  _mergeRouteRecord(
          {
            path: 'theme',
            name: '/user/system/theme',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/user/system/theme.vue'),
            /* no children */
          },
  _definePage_default_8
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'tentative',
        name: '/user/tentative',
        component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/user/tentative.vue'),
        /* no children */
      },
  _definePage_default_9
  )
    ],
  },
  {
    path: '/wallet',
    /* internal name: '/wallet' */
    /* no component */
    children: [
      {
        path: 'address',
        /* internal name: '/wallet/address' */
        /* no component */
        children: [
          {
            path: '',
            name: '/wallet/address/',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/address/index.vue'),
            /* no children */
          },
  _mergeRouteRecord(
          {
            path: 'details',
            name: '/wallet/address/details',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/address/details.vue'),
            /* no children */
          },
  _definePage_default_10
  )
        ],
      },
      {
        path: 'backups',
        /* internal name: '/wallet/backups' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/wallet/backups/',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/backups/index.vue'),
            /* no children */
          },
  _definePage_default_11
  ),
  _mergeRouteRecord(
          {
            path: 'mnemonics',
            name: '/wallet/backups/mnemonics',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/backups/mnemonics.vue'),
            /* no children */
          },
  _definePage_default_12
  ),
  _mergeRouteRecord(
          {
            path: 'verifyMnemonic',
            name: '/wallet/backups/verifyMnemonic',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/backups/verifyMnemonic.vue'),
            /* no children */
          },
  _definePage_default_13
  )
        ],
      },
      {
        path: 'create',
        /* internal name: '/wallet/create' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/wallet/create/',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/create/index.vue'),
            /* no children */
          },
  _definePage_default_14
  ),
  _mergeRouteRecord(
          {
            path: 'after',
            name: '/wallet/create/after',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/create/after.vue'),
            /* no children */
          },
  _definePage_default_15
  ),
  _mergeRouteRecord(
          {
            path: 'mnemonics',
            name: '/wallet/create/mnemonics',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/create/mnemonics.vue'),
            /* no children */
          },
  _definePage_default_16
  ),
  _mergeRouteRecord(
          {
            path: 'privateKey',
            name: '/wallet/create/privateKey',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/create/privateKey.vue'),
            /* no children */
          },
  _definePage_default_17
  )
        ],
      },
      {
        path: 'manage',
        /* internal name: '/wallet/manage' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/wallet/manage/',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/manage/index.vue'),
            /* no children */
          },
  _definePage_default_18
  ),
  _mergeRouteRecord(
          {
            path: 'setting',
            name: '/wallet/manage/setting',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/manage/setting.vue'),
            /* no children */
          },
  _definePage_default_19
  )
        ],
      },
      {
        path: 'password',
        /* internal name: '/wallet/password' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'explain',
            name: '/wallet/password/explain',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/password/explain.vue'),
            /* no children */
          },
  _definePage_default_20
  ),
  _mergeRouteRecord(
          {
            path: 'hint',
            name: '/wallet/password/hint',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/password/hint.vue'),
            /* no children */
          },
  _definePage_default_21
  ),
  _mergeRouteRecord(
          {
            path: 'reset',
            name: '/wallet/password/reset',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/password/reset.vue'),
            /* no children */
          },
  _definePage_default_22
  ),
  _mergeRouteRecord(
          {
            path: 'verify',
            name: '/wallet/password/verify',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/password/verify.vue'),
            /* no children */
          },
  _definePage_default_23
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'receive',
        name: '/wallet/receive',
        component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/receive.vue'),
        /* no children */
      },
  _definePage_default_24
  ),
      {
        path: 'trade',
        /* internal name: '/wallet/trade' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'details',
            name: '/wallet/trade/details',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/trade/details.vue'),
            /* no children */
          },
  _definePage_default_25
  ),
  _mergeRouteRecord(
          {
            path: 'record',
            name: '/wallet/trade/record',
            component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/trade/record.vue'),
            /* no children */
          },
  _definePage_default_26
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'transfer',
        name: '/wallet/transfer',
        component: () => import('D:/projectTest/fantastic-mobile-pro.v0.3.0/src/views/wallet/transfer.vue'),
        /* no children */
      },
  _definePage_default_27
  )
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

